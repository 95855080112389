<template>
  <div class="smart-page__header">
    <DLinkIconVue />
    <p class="title">{{ title }}</p>
  </div>
</template>
<script>
import DLinkIconVue from '../Icons/dLinkIcon.vue'
export default {
  name: 'SmartPageHeaderComponent',
  components: {
    DLinkIconVue,
  },
  props: {
    title: {
      type: String,
      required: true,
      default: () => '',
    },
  },

  data() {
    return {
      items: ['ingredients', 'nutrition', 'allergens', 'company'],
    }
  },
  mounted() {},
  beforeMount() {},
}
</script>
<style lang="scss" scoped>
@import '~/assets/scss/tokens.scss';
.smart-page {
  &__header {
    height: auto;
    border-bottom: 1px solid #f5f5f5;
    padding: 1.438rem 0.75rem 0.75rem 0.75rem;
    display: grid;
    grid-template-columns: 1rem auto;
    align-items: center;
    -moz-column-gap: 10px;
    column-gap: 10px;

    .title {
      color: $DL-black;
      @include font-20-600;
      margin: 0;
      text-overflow: ellipsis;
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}
</style>
