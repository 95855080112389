<template>
  <component :is="contentComponent" :product-info="productInfo" />
</template>
<script>
import FoodSmartPage from './FoodSmartPage/FoodSmartPage.vue'
import WineSmartPage from './WineSmartPage/WineSmartPage.vue'
import CosmeticSmartPage from './CosmeticSmartPage/CosmeticSmartPage.vue'
import CleaningSmartPage from '~/components/SmartPage/CleaningSmartPage/CleaningSmartPage.vue'
import BeerSmartPage from '~/components/SmartPage/BeerSmartPage/BeerSmartPage.vue'

export default {
  name: 'SmartPageComponent',
  props: {
    productInfo: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      contentComponent: null,
    }
  },
  created() {
    this.contentComponent = {
      food: FoodSmartPage,
      wine: WineSmartPage,
      cosmetics: CosmeticSmartPage,
      cleaning: CleaningSmartPage,
      beer: BeerSmartPage,
    }[this.productInfo.industry]
  },
}
</script>
<style lang="scss">
@media (min-width: 600px) {
  .smart-page-wrapper {
    max-width: 400px;
    border: 1px solid #eeeff4;
    border-radius: 0.5rem;
    overflow: hidden;
    max-height: 95%;
  }
}

body {
  margin: 0;

  .content-wrapper.smart-page {
    height: calc(100vh) !important;
    display: flex;
    justify-content: center;
    align-items: center;

    .smart-page-wrapper {
      height: 100%;
      width: 100%;
      display: grid;
      grid-template-rows: 4rem 1fr;
    }
  }
}
</style>
