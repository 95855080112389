var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("table", [
    _c("thead", [
      _c(
        "tr",
        _vm._l(_vm.tableColumns, function (column, key) {
          return _c("th", { key: key }, [
            _c("p", [
              _vm._v(
                "\n          " +
                  _vm._s(
                    _vm.$t(`nutrition.table.column.${key + 1}.title`, {
                      ...column.titleProps,
                    })
                  ) +
                  "\n        "
              ),
            ]),
          ])
        }),
        0
      ),
    ]),
    _vm._v(" "),
    _c(
      "tbody",
      _vm._l(_vm.tableRows, function (row, key) {
        return _c(
          "tr",
          { key: key },
          _vm._l(_vm.tableColumns, function (column, columnKey) {
            return _c("td", { key: `${columnKey}` }, [
              column.key === "name"
                ? _c("span", [_vm._v(" " + _vm._s(row[column.dataIndex]))])
                : _c("span", [
                    _vm._v(
                      " " + _vm._s(row[column.dataIndex]) + _vm._s(row.unit)
                    ),
                  ]),
            ])
          }),
          0
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }