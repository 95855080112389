import ipInt from 'ip-to-int'
import Transformer from '@/transformers'

export const state = () => ({
  design: null,
  uniqueVisit: false,
})

export const getters = {
  getDesign(state) {
    return state.design
  },
}

export const mutations = {
  setState: (state, payload) => {
    state = { ...state, ...payload }
  },
}

export const actions = {
  nuxtServerInit({ state }) {
    const cookieName = this.$config.uniqueVisitCookie
    const cookie = this.$cookiz.get(cookieName)

    if (!cookie) {
      state.uniqueVisit = true

      const expires = new Date()
      expires.setDate(expires.getDate() + 365000)

      this.$cookiz.set(cookieName, true, {
        expires,
      })
    }
  },
  async getIp() {
    return await this.$axios.$get(this.$config.ipApiUrl)
  },
  async getLandingFromShortlink({ state }, shortLink) {
    const url = this.$config.apiRenderUrl.replace('{pathName}', shortLink)

    try {
      return await Transformer.fetch(
        await this.$axios.$get(url, {
          paramsSerializer: function (params) {
            var result = ''
            // Build the query string
            return result
          },
        })
      )
    } catch (error) {
      return Transformer.error(error.response)
    }
  },
  async getCountryClient() {
    const ip = await this.getIp()
    const url = this.$config.statsPinpointUrl.replace('{ip}', ipInt(ip).toInt())

    try {
      const { country } = await this.$axios.$get(url)
      return country || null
    } catch (error) {
      return null
    }
  },
}
