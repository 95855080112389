var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "linkset" }, [
      _c("h2", { staticClass: "head" }, [_vm._v(_vm._s(_vm.$t("Linkset")))]),
      _vm._v(" "),
      _c("div", { staticClass: "subhead" }, [
        _c("div", { staticClass: "link_uri" }, [
          _vm._v(
            "\n        " +
              _vm._s(_vm.$t(`GS1 Digital Link URI:`)) +
              "\n        "
          ),
          _c("a", { attrs: { href: _vm.item.uri, target: "_blank" } }, [
            _vm._v(_vm._s(_vm.item.uri)),
          ]),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "item_tag" }, [
          _vm._v("\n        " + _vm._s(_vm.$t("Item")) + ": "),
          _c("span", { staticClass: "item_title" }, [
            _vm._v(_vm._s(_vm.item.label)),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "links-list-wrapper" }, [
        _c(
          "ul",
          _vm._l(_vm.links, function (link, i) {
            return _c("li", { key: i }, [
              _c("a", { attrs: { href: link.href, target: "_blank" } }, [
                _vm._v(_vm._s(link.href)),
              ]),
              _vm._v(" "),
              _c("ul", [
                _c("li", [
                  _vm._v("\n              " + _vm._s(_vm.$t("Title")) + ": "),
                  _c("span", [_vm._v(_vm._s(link.title))]),
                ]),
                _vm._v(" "),
                _c("li", [
                  _vm._v("\n              " + _vm._s(_vm.$t("Type")) + ": "),
                  _c("span", [_vm._v(_vm._s(link.type))]),
                ]),
                _vm._v(" "),
                _c("li", [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.$t("Language")) +
                      ":\n              "
                  ),
                  _c("span", [
                    _vm._v(_vm._s(link?.lang.length ? link.lang : "-")),
                  ]),
                ]),
              ]),
            ])
          }),
          0
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "header" }, [
      _c("img", {
        attrs: {
          alt: "logo",
          src: require("assets/images/gs1-dl-linkset.png"),
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }