export const QR_VARIANTS_LABELS = {
  10: 'component.modal.add.data.batch.label', // batch
  17: 'component.modal.add.data.exp.date.label', // expiration date
  22: 'component.modal.add.data.exp.date.label', // expiration date
  21: 'component.modal.add.data.serial.number.label', // serial number
}

export const QR_VARIANTS = {
  BATCH: 10,
  SERIAL_NUMBER: 21,
  EXPIRATION_DATE: 17,
  EXPIRATION_DATE_OLD: 22,
  VARIANT: 'qrVariant',
}
