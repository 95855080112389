<template>
  <table>
    <thead>
      <tr>
        <th v-for="(column, key) in tableColumns" :key="key">
          <p>
            {{
              $t(`nutrition.table.column.${key + 1}.title`, {
                ...column.titleProps,
              })
            }}
          </p>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(row, key) in tableRows" :key="key">
        <td v-for="(column, columnKey) in tableColumns" :key="`${columnKey}`">
          <span v-if="column.key === 'name'"> {{ row[column.dataIndex] }}</span>
          <span v-else> {{ row[column.dataIndex] }}{{ row.unit }}</span>
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
export default {
  name: 'NutrientsTable',
  props: {
    nutrientInfo: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    tableColumns() {
      let columns = [
        {
          dataIndex: 'name',
          key: 'name',
        },
        {
          dataIndex: 'quantity',
          key: 'quantity',
          titleProps: {
            quantity: this.nutrientInfo?.serving?.quantity,
            unit: this.nutrientInfo?.serving?.unit,
          },
        },
        {
          dataIndex: 'quantityInPortion',
          key: 'quantityInPortion',
          titleProps: {
            quantity: this.nutrientInfo?.portionServing?.quantity,
            unit: this.nutrientInfo?.portionServing?.unit,
          },
        },
      ]

      if (!this.nutrientInfo?.portionServing) {
        columns = columns.slice(0, -1)
      }

      return columns
    },
    tableRows() {
      return this.nutrientInfo?.nutrients
    },
  },
}
</script>
<style lang="scss" scoped>
@import '~/assets/scss/tokens.scss';
table {
  width: 100%;
  color: $DL-black;

  thead {
    tr {
      th {
        border-right: 3px solid $white-elevation-0;
        background: #f9f9f9;
        padding: 0.5rem;
        @include font-14-700;
        &:first-of-type {
          border-top-left-radius: 0.5rem;
          border-bottom-left-radius: 0.5rem;
        }
        &:last-of-type {
          border-right: 0;
          border-top-right-radius: 0.5rem;
          border-bottom-left-radius: 0.5rem;
        }
        p {
          word-wrap: break-word;
          max-width: min-content;
          text-align: justify;
        }
      }
    }
  }
  tbody {
    tr {
      &:nth-child(even) {
        td {
          background: #f9f9f9;
        }
      }
      td {
        border-right: 3px solid $white-elevation-0;
        color: $DL-black;
        @include font-14-400;
        padding: 0.5rem;

        &:first-of-type{
          @include capitalize-first-letter
        }
      }
    }
  }
}
</style>
