var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CommonModal",
    {
      attrs: {
        "is-visible": _vm.model,
        title: _vm.$t("dashboard.key.qualifiers.label"),
      },
      on: {
        close: function ($event) {
          _vm.model = false
        },
      },
    },
    [
      _vm._l(_vm.qualifiers, function (qualifierValue, i) {
        return _c("div", { key: i, staticClass: "qualifiers_content" }, [
          _c("span", { staticClass: "qualifiers_content__label" }, [
            _vm._v(_vm._s(_vm.$t(_vm.generateVariantLabel(i)))),
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "qualifiers_content__value" }, [
            _vm._v(_vm._s(qualifierValue)),
          ]),
        ])
      }),
      _vm._v(" "),
      _vm._l(_vm.attributes, function (attributeValue, i) {
        return _c("div", { key: i, staticClass: "qualifiers_content" }, [
          _c("span", { staticClass: "qualifiers_content__label" }, [
            _vm._v(_vm._s(_vm.$t(_vm.generateVariantLabel(i)))),
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "qualifiers_content__value" }, [
            _vm._v(_vm._s(attributeValue)),
          ]),
        ])
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }