var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "ingredients-tab" }, [
    _c("div", { staticClass: "header" }, [
      _c("div", { staticClass: "product-info" }, [
        _c("p", { staticClass: "product-info__name" }, [
          _vm._v(_vm._s(_vm.name)),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "product-info__net-content" }, [
          _vm._v(_vm._s(_vm.netContent)),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "product-info__ean" }, [
          _vm._v(_vm._s(_vm.ean)),
        ]),
        _vm._v(" "),
        _c("img", {
          staticClass: "cruelty-free-logo",
          attrs: { src: _vm.crueltyFreeLogo, alt: "cruelty free logo" },
        }),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "image-wrapper",
          class: { placeholder: !_vm.hasThumbnail },
        },
        [
          _vm.hasThumbnail
            ? _c("img", {
                attrs: { src: _vm.source?.thumbnail, alt: "product image" },
              })
            : _c("product-thumbnail-placeholder"),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "content" }, [
      _c("div", { staticClass: "container" }, [
        _c("p", { staticClass: "title" }, [
          _vm._v(_vm._s(_vm.$t("ingredients.page.active.title"))),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "content" }, [_vm._v(_vm._s(_vm.ingredients))]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "container" }, [
        _c("p", { staticClass: "title" }, [
          _vm._v(_vm._s(_vm.$t("ingredients.page.inci.title"))),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "content" }, [_vm._v(_vm._s(_vm.inci))]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }