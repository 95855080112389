<template>
  <div class="featured-nutrient">
    <p class="featured-nutrient__value">{{ nutrient.quantity }}</p>
    <p class="featured-nutrient__label">
      <span v-if="nutrient.name">{{ nutrient.name }},</span> {{ nutrient.unit }}
    </p>
  </div>
</template>
<script>
export default {
  name: 'FeaturedNutrientItem',
  props: {
    nutrient: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
<style lang="scss" scoped>
@import '~/assets/scss/tokens.scss';
.featured-nutrient {
  display: grid;
  grid-template-rows: 1.2rem auto;
  row-gap: 0.25rem;

  p {
    text-align: center;
    color: $DL-black;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &:nth-of-type(2) {
      @include capitalize-first-letter;
    }
  }

  &__value {
    @include font-20-600;
  }

  &__label {
    @include font-14-400;
  }
}
</style>
