var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "instruction-wrapper" },
    [
      _c("div", { staticClass: "title" }, [
        _vm._v(_vm._s(_vm.$t("manual.tab.label"))),
      ]),
      _vm._v(" "),
      _vm._l(_vm.instructions, function (instruction, key) {
        return _c("div", { key: key, staticClass: "instruction" }, [
          _c("span", { staticClass: "step" }, [
            _vm._v(_vm._s(`Paso ${key + 1}`)),
          ]),
          _vm._v(" "),
          _c("p", [_vm._v(_vm._s(instruction))]),
        ])
      }),
      _vm._v(" "),
      _c("div", { staticClass: "title", staticStyle: { margin: "10px 0" } }, [
        _vm._v(
          "\n    " + _vm._s(_vm.$t("manual.page.precautions.title")) + "\n  "
        ),
      ]),
      _vm._v(" "),
      _c("p", [_vm._v(_vm._s(_vm.precautions))]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }