var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: { loader: true, fadeout: !_vm.isLoading } }, [
    _c("p", [_vm._v(_vm._s(_vm.$t("loading.page.title")))]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }