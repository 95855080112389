<template>
  <div class="container">
    <div class="header">
      <img alt="logo" src="~/assets/images/gs1-dl-linkset.png" />
    </div>
    <div class="linkset">
      <h2 class="head">{{ $t('Linkset') }}</h2>
      <div class="subhead">
        <div class="link_uri">
          {{ $t(`GS1 Digital Link URI:`) }}
          <a :href="item.uri" target="_blank">{{ item.uri }}</a>
        </div>

        <p class="item_tag">
          {{ $t('Item') }}: <span class="item_title">{{ item.label }}</span>
        </p>
      </div>
      <div class="links-list-wrapper">
        <ul>
          <li v-for="(link, i) in links" :key="i">
            <a :href="link.href" target="_blank">{{ link.href }}</a>
            <ul>
              <li>
                {{ $t('Title') }}: <span>{{ link.title }}</span>
              </li>
              <li>
                {{ $t('Type') }}: <span>{{ link.type }}</span>
              </li>
              <li>
                {{ $t('Language') }}:
                <span>{{ link?.lang.length ? link.lang : '-' }}</span>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Linkset',
    },
    item: {
      type: Object,
      default: () => {},
    },
    links: {
      type: Array,
      default: () => [],
    },
  },
  head() {
    return {
      title: this.title,
    }
  },
}
</script>

<style scoped lang="scss">
.container {
  font-family: Inter, serif;
  display: flex;
  flex-direction: column;
  row-gap: 42px;
  padding: 24px 45px;
  height: 100%;
  width: inherit;
}

.header,
.linkset {
  width: inherit;
}

.header {
  height: 84px;
  max-height: 84px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  background: linear-gradient(
    359deg,
    #fff -7.12%,
    rgba(228, 235, 240, 0.3) 42.65%,
    rgba(199, 213, 224, 0.6) 96.21%
  );
  position: relative;

  img {
    width: 162px;
    height: 49.255px;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 20px;
  }
}

.linkset {
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  overflow-x: hidden;
  max-width: 100%;

  h2 {
    font-size: 30px;
    font-weight: 600;
    margin: 0;
  }

  .subhead {
    display: flex;
    flex-direction: column;
    row-gap: 16px;

    .link_uri {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      margin: 0;
    }

    .item_tag {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      margin: 0;
    }
  }

  .links-list-wrapper {
    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;

      li {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 4px;

        a {
          color: rgba(98, 99, 241, 1);
          font-weight: 400;
          line-height: 22px;
          margin-bottom: 84px;
          word-break: break-all;

          &:hover {
            text-decoration: underline;
          }
        }

        span {
          font-weight: 400;
        }
      }

      ul {
        list-style-type: none;
        margin-top: 4px;
        margin-bottom: 24px;
      }
    }
  }

  .item_title {
    font-weight: 400;
    font-size: 16px;
  }
}

@media (max-width: 600px) {
  .container {
    padding: 16px;
  }
}
</style>
