<template>
  <div :class="{ loader: true, fadeout: !isLoading }">
    <p>{{$t('loading.page.title')}}</p>
  </div>
</template>

<script>
export default {
  name: 'LoadingScreen',
  props: {
    isLoading: Boolean
  }
}
</script>

<style>
.loader {
  height: 100vh;
  position: relative;
 
}

.loader p  {
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    bottom: 0;
    height: fit-content;
    width: 100%;
}
.fadeout {
  animation: fadeout 2s infinite;
}

@keyframes fadeout {
  to {
    opacity: 0;
    visibility: hidden;
  }
}
</style>
