var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "featured-nutrient" }, [
    _c("p", { staticClass: "featured-nutrient__value" }, [
      _vm._v(_vm._s(_vm.nutrient.quantity)),
    ]),
    _vm._v(" "),
    _c("p", { staticClass: "featured-nutrient__label" }, [
      _vm.nutrient.name
        ? _c("span", [_vm._v(_vm._s(_vm.nutrient.name) + ",")])
        : _vm._e(),
      _vm._v(" " + _vm._s(_vm.nutrient.unit) + "\n  "),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }